import Button from 'components/base/Button'
import FormItem from 'components/base/FormItem'
import Input from 'components/base/Input'
import { FC, c } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Updater } from 'use-immer'
import { LoginFormData, LoginProcessState } from './lib/types'
import PasswordInput from 'components/base/PasswordInput'
import { ArrowNarrowRightIcon } from '@heroicons/react/solid'
import { useIntl } from 'lib/intl-utils'
import Link from 'components/base/Link'
import { match } from 'ts-pattern'
import { useRouter } from 'next/router'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import PhoneNumberInput from 'components/base/PhoneNumberInput'
import { usePrevious, useStateFromLocalStorage } from 'lib/hooks'
import {
  AuthenticationResponseJSON,
  browserSupportsWebAuthnAutofill,
  platformAuthenticatorIsAvailable,
  startAuthentication,
} from '@simplewebauthn/browser'
import Separator from 'components/base/Separator'
import { FingerPrintIcon } from '@heroicons/react/outline'
import { useToast } from 'components/app/NotificationArea'
import { loginSignalAtom } from './lib/atoms'
import { useSetAtom } from 'jotai'

type Props = {
  processState: LoginProcessState
  setProcessState: Updater<LoginProcessState>
  redirect?: string
  error?: string
}

const LoginForm: FC<Props> = ({ processState, setProcessState, redirect, error }) => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  const [generalError, setGeneralError] = useState<string | null>(
    error ? t`login.oauth_error` + ' ' + error : null
  )
  const [passwordRequired, setPasswordRequired] = useState(ctx.theme.hostType !== 'sellmonitor')
  const [phoneOrEmail, setPhoneOrEmail] = useState<'phone' | 'email'>('email')
  const [automaticAddedPrefix, setAutomaticAddedPrefix] = useState<string | null>(null)
  const [eightSwappedToSeven, setEightSwappedToSeven] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm<LoginFormData>({ mode: 'onSubmit' })
  const router = useRouter()
  const username = watch('username')
  const [platformAuthenticatorAvailable, setPlatformAuthenticatorAvailable] = useState<
    boolean | null
  >(null)
  const [lastLoginType, setLastLoginType] = useState<'webauthn' | 'username' | 'vk' | 'psb' | null>(
    null
  )
  const setLoginSignal = useSetAtom(loginSignalAtom)
  const showToast = useToast()

  useEffect(() => {
    platformAuthenticatorIsAvailable().then(setPlatformAuthenticatorAvailable)
  }, [])

  useEffect(() => {
    const lastLoginType = document.cookie
      .split('; ')
      .find((row) => row.startsWith('lastLoginType='))
    if (lastLoginType) {
      const [type] = lastLoginType.split('=')[1].split(',')
      setLastLoginType(type as 'webauthn' | 'username' | 'vk' | 'psb')
    }
  }, [])

  const previousUsername = usePrevious(username)

  const tryWebauthnAutofill = useCallback(async () => {
    const options = await ctx.getLogInWithAuthenticatorOptions()
    let credential: AuthenticationResponseJSON | null = null
    try {
      credential = await startAuthentication({
        optionsJSON: options,
        useBrowserAutofill: true,
      })
    } catch (error: any) {}
    if (credential) {
      ctx
        .logInWithAuthenticator(credential as any)
        .then((res) => {
          if (res.status === 'ok') {
            setProcessState((draft) => {
              draft.stage = 'success'
            })
            setTimeout(() => {
              setLoginSignal((s) => !s)
            }, 5000)
            router.push(redirect ?? '/products/list/')
          }
        })
        .catch((error: any) => {
          showToast('error', t`error`, t`login.webauthn_error`)
        })
    }
  }, [ctx, redirect, router, setProcessState])

  const startActiveWebauthnAttempt = useCallback(async () => {
    const options = await ctx.getLogInWithAuthenticatorOptions()
    let credential: AuthenticationResponseJSON | null = null
    try {
      setProcessState((draft) => {
        draft.stage = 'waitingForPasskey'
      })
      credential = await startAuthentication({
        optionsJSON: options,
      })
    } catch (error: any) {
      setProcessState((draft) => {
        draft.stage = 'enteringData'
      })
    }
    if (credential) {
      ctx
        .logInWithAuthenticator(credential as any)
        .then((res) => {
          if (res.status === 'ok') {
            setTimeout(() => {
              setLoginSignal((s) => !s)
            }, 5000)
            setProcessState((draft) => {
              draft.stage = 'success'
            })
            router.push(redirect ?? '/products/list/')
          }
        })
        .catch((error: any) => {
          setProcessState((draft) => {
            draft.stage = 'enteringData'
          })
          showToast('error', t`error`, t`login.webauthn_error`)
        })
    }
  }, [ctx, redirect, router, setProcessState])

  useEffect(() => {
    if (ctx.theme.hostType === 'sellmonitor') {
      browserSupportsWebAuthnAutofill().then((supports) => {
        if (supports) {
          tryWebauthnAutofill()
        }
      })
    }
  }, [])

  useEffect(() => {
    if (username == null) {
      setPhoneOrEmail('email')
      setAutomaticAddedPrefix(null)
      setEightSwappedToSeven(false)
      return
    }

    if (username.includes(' ')) {
      setValue('username', username.replaceAll(' ', ''))
      return
    }

    if (phoneOrEmail === 'phone') {
      const previous = previousUsername ?? ''
      const previousLength = previous.length
      const currentLength = username.length

      if (currentLength > previousLength + 1 && automaticAddedPrefix == null) {
        const automaticPart = username.slice(0, currentLength - previous.length - 1)
        setAutomaticAddedPrefix(automaticPart)
      }
    }

    if (username.includes('@')) {
      setPhoneOrEmail('email')
      let newUsername = username.slice(automaticAddedPrefix?.length ?? 0)
      if (eightSwappedToSeven) {
        newUsername = '8' + newUsername
      }
      setValue('username', newUsername)
      setAutomaticAddedPrefix(null)
      setEightSwappedToSeven(false)
    } else if (
      username.startsWith('+') ||
      username.startsWith('7') ||
      username.startsWith('8') ||
      username.startsWith('9')
    ) {
      setPhoneOrEmail('phone')
      if (username.startsWith('9')) {
        setValue('username', '+7' + username)
        setAutomaticAddedPrefix('+7')
      }
      if (username.startsWith('8')) {
        setValue('username', '+7' + username.slice(1))
        setAutomaticAddedPrefix('+7')
        setEightSwappedToSeven(true)
      }
    } else {
      setPhoneOrEmail('email')
    }
  }, [username])

  const onUserDataSubmit = useCallback(async (data: LoginFormData) => {
    window.scrollTo({ top: 0 })

    const response = await ctx.logIn(data)

    match(response)
      .with({ status: 'AuthSuccess' }, () => {
        router.push('/products/list/')
      })
      .with({ status: 'AuthNeedTwoFactor' }, (response) => {
        setProcessState((s) => {
          s.stage = 'waitingForCode'
          s.data = data
          s.codeType = response.type
          s.phone = response.phone
        })
      })
      .with({ status: 'PasswordRequired' }, () => {
        setPasswordRequired(true)
      })
      .with({ status: 'UserNotFound' }, () => {
        setGeneralError(t`login.user_not_found`)
      })
      .with({ status: 'InvalidCredentials' }, () => {
        setGeneralError(t`login.invalid_credentials`)
      })
      .with({ status: 'UserBanned' }, () => {
        setGeneralError(t`login.user_banned`)
      })
      .with({ status: 'AttemptsLimitReached' }, (response) => {
        setGeneralError(
          t`login.attempts_limit_reached` +
            ` ${response.seconds} ` +
            t('second_accusative', { count: response.seconds })
        )
      })
      .exhaustive()
  }, [])

  useEffect(() => {
    if (!processState.data) return
    reset(processState.data)
  }, [processState.data])

  return (
    <>
      <form
        onSubmit={handleSubmit(onUserDataSubmit)}
        className="grid grid-cols-1 gap-x-6 gap-y-6 mt-16 sm:grid-cols-2"
      >
        <FormItem
          label={ctx.theme.hostType === 'sellmonitor' ? 'Телефон или email' : 'Email'}
          error={errors.username?.message}
          className="col-span-full"
        >
          {phoneOrEmail === 'email' ? (
            <Input
              autoFocus
              {...register('username', {
                required: t`required_field`,
              })}
              hasError={Boolean(errors.username?.message)}
              type="email"
              autoComplete="username webauthn"
            />
          ) : (
            <PhoneNumberInput
              autoFocus
              onKeyDown={(e) => {
                const key = e.key
                if (username == null) return

                if (
                  key === 'Backspace' &&
                  (!username || username.length === 0 || username.length === 1)
                ) {
                  e.preventDefault()
                  setPhoneOrEmail('email')
                }
                if (key === '@') {
                  setPhoneOrEmail('email')
                }
              }}
              name="username"
              autoComplete="username webauthn"
              rules={{
                required: t`required_field`,
                validate: {
                  isPossible: (v: string) =>
                    isPossiblePhoneNumber(v) || t`registration.invalid_phone`,
                },
              }}
              control={control as any}
              hasError={Boolean(errors.username?.message)}
            />
          )}
        </FormItem>
        {passwordRequired && (
          <FormItem label={t`password`} error={errors.password?.message} className="col-span-full">
            <PasswordInput
              {...register('password', {
                required: t`required_field`,
              })}
              hasError={Boolean(errors.password?.message)}
              autoComplete="password"
            />
          </FormItem>
        )}
        <div className="col-span-full space-y-2">
          {generalError && <p className="text-red-600">{generalError}</p>}
          <Button
            disabled={processState.stage === 'waitingForPasskey'}
            type="submit"
            theme={lastLoginType === 'username' || lastLoginType === null ? 'primary' : 'white'}
            className={c`justify-center space-x-1 w-full`}
          >
            <span>{t`log_in`} </span>
            <ArrowNarrowRightIcon className="w-4 h-4" />
          </Button>
          {ctx.theme.hostType === 'sellmonitor' && (
            <div className="relative">
              <Separator orientation="horizontal" className="my-8" />
              <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 bg-background px-2.5 bg-white text-xs text-gray-500">
                {t`or`}
              </div>
            </div>
          )}
          {ctx.theme.hostType === 'sellmonitor' && platformAuthenticatorAvailable && (
            <div className="flex flex-col">
              <Button
                disabled={processState.stage === 'waitingForPasskey'}
                theme={lastLoginType === 'webauthn' ? 'primary' : 'white'}
                onClick={startActiveWebauthnAttempt}
                className={c`self-center w-full flex justify-center`}
              >
                <FingerPrintIcon className="size-5" />
                {processState.stage === 'waitingForPasskey' ? (
                  <span className="w-max">{t`login.waiting_for_passkey`}</span>
                ) : (
                  <span>{t`login.try_webauthn`}</span>
                )}
              </Button>
              {lastLoginType === 'webauthn' && (
                <div className="text-[10px] text-center text-gray-500 mt-1">
                  {t`login.you_used_this_last_time`}
                </div>
              )}
            </div>
          )}
          {ctx.theme.hostType === 'sellmonitor' && (
            <div className="flex flex-col">
              <a
                href="/oauth/start/vk/"
                className={c`focus:ring-primary-500 font-medium text-sm rounded-md h-[38px] flex items-center justify-center gap-[7px] ${
                  lastLoginType === 'vk'
                } bg-[#0077FF] border border-[#0077FF] hover:bg-[#0071F2] text-white | hover:bg-[#F5F5F7] bg-white border border-black/[0.12]`}
              >
                <img
                  src={`/next/img/oauth/vk_logo_${lastLoginType === 'vk' ? 'white' : 'blue'}.svg`}
                  className="size-6"
                />
                <span>{t`login.sign_in_with_vk_id`}</span>
              </a>
              {lastLoginType === 'vk' && (
                <div className="text-[10px] text-center text-gray-500 mt-1">
                  {t`login.you_used_this_last_time`}
                </div>
              )}
            </div>
          )}
          {ctx.theme.hostType === 'psb' && (
            <div className="flex flex-col">
              <Button
                as="a"
                href="/oauth/start/psb/"
                theme="white"
                className="flex items-center justify-center gap-0.5"
              >
                <img src={`/next/psb/images/logo-fixed.svg`} className="size-5" />
                <span>{t`login.sign_in_with_psb`}</span>
              </Button>
              {lastLoginType === 'psb' && (
                <div className="text-[10px] text-center text-gray-500 mt-1">
                  {t`login.you_used_this_last_time`}
                </div>
              )}
            </div>
          )}
          <p className="text-xs text-center text-gray-500 !mt-8">
            {ctx.theme.hostType === 'sellmonitor' && (
              <>
                Входя в аккаунт, вы соглашаетесь с{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/public-offer/"
                  className="text-primary-600"
                >
                  публичной офертой
                </a>{' '}
                и{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/doc/sellmonitor/ru/privacy_policy.pdf"
                  className="text-primary-600"
                >
                  политикой конфиденциальности
                </a>
              </>
            )}
            {ctx.theme.hostType === 'sellscreen' && (
              <>
                By logging in, you agree to the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/doc/sellscreen/en/terms.pdf"
                  className="text-primary-600"
                >
                  Terms of Service
                </a>{' '}
                and the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/doc/sellscreen/en/privacy_policy.pdf"
                  className="text-primary-600"
                >
                  Privacy Policy
                </a>
              </>
            )}
          </p>
        </div>
      </form>
      {passwordRequired && ctx.theme.hostType !== 'psb' && (
        <div className="mt-8 text-center">
          <Link
            href="/reset-password/"
            className="text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            {t`login.forgot_password`}?
          </Link>
        </div>
      )}
      {['sellmonitor', 'sellscreen', 'uzum'].includes(ctx.theme.hostType) && (
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2">
          <div className="flex items-center space-x-4 text-sm text-gray-500">
            <p className="w-max">{t`login.not_registered`}?</p>
            <Button theme="white" as={Link} href="/signup/">
              {t`login.sign_up`}
            </Button>
          </div>
        </div>
      )}
      {['sellematics'].includes(ctx.theme.hostType) && (
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2">
          <div className="flex items-center space-x-4 text-sm text-gray-500">
            <p className="w-max">{t`login.not_registered_sellematics`}?</p>
            <Button theme="white" as="a" className="w-max" href="/#access">
              {t`login.contact_us`}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default LoginForm
