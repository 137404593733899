import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useRouter } from 'next/router'
import { LoginProcessState } from './lib/types'
import { Updater } from 'use-immer'
import { CheckIcon } from '@heroicons/react/outline'
import { useIntl } from 'lib/intl-utils'

type Props = {
  processState: LoginProcessState
  setProcessState: Updater<LoginProcessState>
  redirect?: string
}

const PasskeyIntegration: FC<Props> = ({ processState, setProcessState, redirect }) => {
  const ctx = useUserContext()
  const { t } = useIntl()
  const router = useRouter()

  return processState.stage === 'success' ? (
    <div className="mt-16 flex gap-4 items-center justify-center">
      <div className="flex items-center justify-center rounded-full bg-green-500 w-12 h-12">
        <CheckIcon className="w-6 h-6 text-white" />
      </div>
      <div className="flex flex-col">
        <div className="text-lg font-medium">{t`login.success`}</div>
        <div className="text-sm text-gray-600">{t`login.redirecting`}</div>
      </div>
    </div>
  ) : null
}

export default PasskeyIntegration
