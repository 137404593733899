
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getServerSideProps as __Next_Translate_old_getServerSideProps__195d712a602__ } from 'components/auth/LoginPage';
export { default } from 'components/auth/LoginPage';

    async function __Next_Translate__getServerSideProps__195d712a602__(ctx) {
      const res = await __Next_Translate_old_getServerSideProps__195d712a602__(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__195d712a602__ as getServerSideProps }
  