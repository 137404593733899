import { c, FC } from 'lib/component-utils'
import * as This from '@radix-ui/react-separator'

const Separator: FC<{}, typeof This.Root> = ({ className, orientation, ...rest }) => {
  return (
    <This.Root
      decorative
      orientation={orientation}
      className={c`bg-gray-200 ${orientation === 'horizontal'} h-px | w-px ${className}`}
      {...rest}
    />
  )
}

export default Separator
