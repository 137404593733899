import { FC } from 'lib/component-utils'
import { GetServerSideProps } from 'next'
import { withUserProps } from 'lib/contextCreation'
import { useImmer } from 'use-immer'
import AuthLayout from 'components/auth/AuthLayout'
import { LoginProcessState } from 'components/auth/lib/types'
import ConfirmationCodeForm from 'components/auth/ConfirmationCodeForm'
import { useIntl } from 'lib/intl-utils'
import LoginForm from 'components/auth/LoginForm'
import PasskeyIntegration from './PasskeyIntegration'

export const getServerSideProps: GetServerSideProps = withUserProps(
  async ({ pageContext, userContext }) => {
    if (userContext.isAuthorized) {
      return {
        redirect: {
          permanent: false,
          destination: '/products/list/',
        },
      }
    }

    const redirect = pageContext.query.redirect ?? null
    const error = pageContext.query.error ?? null

    return {
      props: {
        isResponsive: true,
        disableFooter: true,
        redirect,
        error,
      },
    }
  },
  { allowUnauthorizedUsers: true, forceUnauthorizedMode: process.env.NODE_ENV === 'development' }
)

type Props = {
  redirect?: string
  error?: string
}

const LoginPage: FC<Props> = ({ redirect, error }) => {
  const { t, lang } = useIntl()

  const [processState, setProcessState] = useImmer<LoginProcessState>({
    type: 'login',
    stage: 'enteringData',
    data: null,
    codeType: 'sms',
    phone: '',
  })

  return (
    <AuthLayout pageName={t`login.login`}>
      <PasskeyIntegration
        processState={processState}
        setProcessState={setProcessState}
        redirect={redirect}
      />
      {(processState.stage === 'enteringData' || processState.stage === 'waitingForPasskey') && (
        <LoginForm
          processState={processState}
          setProcessState={setProcessState}
          redirect={redirect}
          error={error}
        />
      )}
      {processState.stage === 'waitingForCode' && (
        <ConfirmationCodeForm
          processState={processState}
          setProcessState={setProcessState}
          redirect={redirect}
        />
      )}
    </AuthLayout>
  )
}

export default LoginPage
